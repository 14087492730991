import React from "react"
import { Link, useTranslation } from "gatsby-plugin-react-i18next"

import List from "./list"

import IconLink from "../images/inline/icon-link.svg"


const LearningUnitItem = ({ lu, number, open }) => {
  const { t } = useTranslation()

  const { title, short_abstract, teaching_time_minutes, duration_repeat, path, number: lu_number } = lu
  const { phase } = lu?.relationships

  const formats = lu?.relationships.format.map(item => item.name).filter(item => item !== "Group work" && item !== "Individual work")
  const locations = lu?.relationships.location.map(item => item.name)
  const roles = lu?.relationships.partners.map(item => item.name)
  const subjects = lu?.relationships.steam_subjects.map(item => item.name)
  const engagements = lu?.relationships.engagement.map(item => item.name)


  return <div className={`lu-item ${phase ? `phase-${phase.drupal_id}` : "phase-none"}`}>
    { phase && <div className="lu-item-phase">{ phase.name }</div> }
    <div className="pane">
      <div className="lu-number">{ lu_number ? lu_number : number+1 }</div>

      <div className="lu-item-header pad-s">
        {/* <div className="clickable" onClick={ ()=> { handleOpenLuUpdate(number) } }> */}
        <Link className="no-underline" to={path.alias}>
          <h3>{ title }</h3>
          <em>
            { formats.join(" - ") }

            { teaching_time_minutes && <span> (
              { duration_repeat !== 1 && duration_repeat !== null ? `${duration_repeat} × ` : "" }
              { t("MIN", { count: teaching_time_minutes }) })
            </span> }
          </em>
        </Link>
        <div className="no-print">
          {/* <button className="icon-button" onClick={ ()=> { handleOpenLuUpdate(number) } }>{ open ? "collapse" : "expand" }</button><br/> */}
          <Link className="icon-button icon-button-more" to={path.alias} language={path.langcode}>
            { t("MORE") }
            <IconLink />
          </Link>
        </div>
      </div>

      <div className={ !open ? "no-screen" : "" }>
        <div className="pad-s">
          <p>{ short_abstract }</p>
        </div>

        <div className="lu-card-footer pad-s grid-columns narrower-columns">
          <div className="what">
            <List title={ t("SEARCH-WHAT") } titleWeight="h4" type="lines" items={subjects} />
          </div>
          <div className="who">
            <List title={ t("SEARCH-WHO") } titleWeight="h4" type="lines" items={roles} />
          </div>
          <div className="where">
            <List title={ t("SEARCH-WHERE") } titleWeight="h4" type="lines" items={locations} />
          </div>
          <div className="with">
            <List title={ t("SEARCH-WITH") } titleWeight="h4" type="lines" items={engagements} />
          </div>
        </div>
      </div>

    </div>
  </div>
}

export default LearningUnitItem
