import React, { useState, useContext } from "react"

import { graphql } from "gatsby"

import { MyContext } from "../context/my-context"
import { upsert, remove } from "../context/records-reducer"
import { useI18next } from "gatsby-plugin-react-i18next"

import { printserviceUrl } from "../settings"

import { updateCountsArray, sortArrayOfObjectsBy } from "../util/data"
import { getParsedHtml, makeAgeRangeString, makeTimeString } from "../util/format"

import Seo from "../parts/seo"
import Breadcrumb from "../parts/breadcrumb"
import Row from "../parts/row"
import Layout from "../parts/layout"
import List from "../parts/list"
import TabSections from "../parts/tabSections"
import LearningUnitItem from "../parts/learningUnitItem"
import HeadingUnderline from "../parts/headingUnderline"
import WwwwInfo from "../parts/wwwwInfo"

import CcBySa from "../images/inline/cc-by-sa.svg"

import Print from "../images/inline/icon-print.svg"
import Pdf from "../images/inline/icon-pdf.svg"
import Favourite from "../images/inline/icon-favourite.svg"
import Share from "../images/inline/icon-share.svg"
import NotFavourite from "../images/inline/icon-not-favourite.svg"
import Edit from "../images/inline/icon-edit.svg"

import Compact from "../images/inline/icon-compact.svg"
import CompactActive from "../images/inline/icon-compact-active.svg"
import Full from "../images/inline/icon-full.svg"
import FullActive from "../images/inline/icon-full-active.svg"
import { getNewCustomLsId } from "../util/data"




const LearningScenario = ({ data }) => {
  const { t, navigate, language } = useI18next()

  // destructure/massage data a bit
  const {
    id,
    drupal_id,
    title, subtitle,
    minimum_age, maximum_age,
    //duration,
    the_big_idea, the_challenge, short_abstract,
    main_goals, main_messages,
    uh_oh_moments, aha_moments, general_tips
  } = data.nodeLearningScenario

  const authors = data.nodeLearningScenario.relationships.authors.map(author => {
    return {
      persons: author.persons,
      organisation: author.relationships.organisation
    }
  })

  const images = data.nodeLearningScenario.image?.map((img, i) => {
    return {
      ...img,
      ...data.nodeLearningScenario.relationships.image[i]
    }
  })

  const learning_units = data.nodeLearningScenario.relationships.learning_units.map(lu => lu.relationships.learning_unit_reference).filter(lu => lu !== null)

  const time = learning_units.reduce((agg, lu) => {
    return agg + (lu?.teaching_time_minutes || 0) * (lu?.duration_repeat || 1)
  }, 0)

  //whowhatwherewith
  const subjectsWithCount = []
  const partnersWithCount = []
  const locationsWithCount = []
  const engagementsWithCount = []
  learning_units.forEach(lu => {
    lu?.relationships.steam_subjects.forEach(item => { updateCountsArray(subjectsWithCount, item.name) })
    lu?.relationships.partners.forEach(partner => { updateCountsArray(partnersWithCount, partner.name) })
    lu?.relationships.location.forEach(location => { updateCountsArray(locationsWithCount, location.name) })
    lu?.relationships.engagement.forEach(engagement => { updateCountsArray(engagementsWithCount, engagement.name) })
  })
  sortArrayOfObjectsBy(subjectsWithCount, "count")
  sortArrayOfObjectsBy(partnersWithCount, "count")
  sortArrayOfObjectsBy(locationsWithCount, "count")
  sortArrayOfObjectsBy(engagementsWithCount, "count")


  const breadcrumbItems = [
    {
      label: t("NAV-HOME"),
      link: "/",
    },
    {
      label: t("NAV-MATERIALS"),
      link: "/open-schooling-materials",
    }
  ]

  const { state, dispatch } = useContext(MyContext)
  const favourite = state?.favourites.find((record) => record.id === drupal_id )

  const [openLus, setOpenLus] = useState(new Array(learning_units.length).fill(false))

  const handleOpenLuUpdate = (i) => {
    const newOpenLus = [...openLus]

    newOpenLus[i] = !newOpenLus[i]

    setOpenLus(newOpenLus)
  }

  const handleOpenLusUpdate = (value) => {
    const newOpenLus = new Array(learning_units.length).fill(value)

    setOpenLus(newOpenLus)
  }

  const handleEditLsClick = () => {
    /*const ids = state.ls.map(ls => ls.id)

    let i = 1
    while(ids.indexOf(i) !== -1) {
      i++;
    }

    navigate(`/build-your-own/${i}`, {
      state: {
        cloneFrom: id
      }
    })*/
    
    const newCustomLsId = getNewCustomLsId(state.ls)

    navigate(`/build-your-own/edit`, {
      state: {
        hiddenid: newCustomLsId,
        cloneFrom: id,
      }
    })
  }


  const handleShareLsClick = () => {
    const url = new URL(window.location)

    dispatch.dialog({
      title: t("SHARE-LS-TITLE"),
      body: <>
        <p>{ t("SHARE-LS-BODY") }:</p>
        <pre className="share-url">{ url.href }</pre>
      </>,
      buttons: [
        <a key={"twitter"} className="button" href={`https://twitter.com/intent/tweet?url=${url.href}`} target="_blank" rel="noopener noreferrer" title={ t("twitter") }>
          { t("SHARE-TWITTER") }
        </a>,
        <button key={"copy"} className="button" onClick={ (e) => { navigator.clipboard.writeText(url.href); e.target.innerText = t("SHARE-COPIED_TO_CLIPBOARD") } }>
          { t("SHARE-COPY_TO_CLIPBOARD") }
        </button>
      ]
    })
  }

  const handlePdfClick = () => {
    if(printserviceUrl) {
      window.open(`${printserviceUrl}/generate?url=${window.location}`, '_blank');
    } else {
      dispatch.dialog({
        title: t("PDF-DIALOG_TITLE"),
        body: <>
          <p>{ t("PDF-DIALOG_BODY") }</p>
        </>,
        buttons: [
          <button key={"copy"} className="button">
            <Print />
            { t("BUTTONS-PRINT") }
          </button>
        ]
      })
    }
  }


  return (
    <Layout links={data.allNodeLearningScenario?.nodes} currentNavItem={"open-schooling-materials"}>
      <Seo title={ title } />

      <div id="page-header">
        <div className="space-between block-margin-s no-print">
          <div>
            <Breadcrumb items={ breadcrumbItems } />
          </div>
        </div>

        <div className="pane">
          <HeadingUnderline title={ title } subtitle={ subtitle } classes={["center", "pad-m"]} />

          { images && images.length > 0 && <div className="image-ratio image-ratio-5-2 ls-header-image offset-up print-less-width">
            <img src={ images[0].styles?.large } alt={images[0].alt} width={images[0].width} height={images[0].height} data-object-fit="cover" />
          </div>}

          <div className="space-between pad-s print-less-width">
            <div>
              <strong>{ t("SEARCH-AGE") }:</strong> {makeAgeRangeString(minimum_age, maximum_age)}
            </div>

            <div>
              <strong>{ t("LS-DURATION") }:</strong> {learning_units.length} { t("LEARNING_UNITS") } ({ makeTimeString(time, t) })
            </div>
          </div>

        </div>

      </div>

      <Row classes={["space-between s-margins print-less-width"]}>
        <div id="ls-credits">
          { t("LS-MADE_BY") }:
          { authors.map((author, i) => {
            const formatter = new Intl.ListFormat(language, { style: "long", type: "conjunction" })

            return <span key={ i }>
              { author?.organisation?.title }
              { (author?.persons || []).length > 0 && <>
                { ": " }
                { formatter.format(author.persons) }
              </> }

              { authors.length !== i+1 && " - " }
            </span>
          }) }
        </div>

        <a id="ls-license" href="https://creativecommons.org/licenses/by-sa/4.0/" target="_blank" rel="noopener noreferrer" aria-label="CC-BY-SA license information">
          <CcBySa />
        </a>
      </Row>

      <Row classes={["no-print"]}>
        <h2 className="center">{ t("ACTION") }</h2>
        <div className="ctas center">
          <button className="button" onClick={ () => { window.print(); return false; }}>
              <Print /> { t("BUTTONS-PRINT") }
          </button>

          { favourite ?
            <button className={`button ${favourite ? "active" : ""}`} onClick={ ()=> { remove(dispatch.favourites, drupal_id) } }>
              <Favourite /> { t("FAVOURITES-REMOVE") }
            </button>
            :
            <button className={`button ${favourite ? "active" : ""}`} onClick={ ()=> { upsert(dispatch.favourites, {id: drupal_id, type:"ls"}) } }>
              <NotFavourite /> { t("FAVOURITES-ADD") }
            </button>
          }

          <button className="button" onClick={() => handleShareLsClick()}>
            <Share /> { t("BUTTONS-SHARE") }
          </button>

          <button className="button" onClick={() => handlePdfClick()}>
            <Pdf /> { t("BUTTONS-PDF") }
          </button>

          <button className="button" onClick={() => handleEditLsClick()}>
            <Edit /> { t("BUTTONS-EDIT") }
          </button>
        </div>
      </Row>

      <Row>
        <div className="pane pad-m">
          <WwwwInfo subjectsWithCount={ subjectsWithCount } partnersWithCount={ partnersWithCount} locationsWithCount={ locationsWithCount } engagementsWithCount={ engagementsWithCount } luCount={ learning_units.length } />
        </div>
      </Row>

      <TabSections activeDefault={1} tabs={[
        {
          title: t("LS-GENERAL_INFO"),
          body: <Row>
            <div className="text-with-sidebar">
              <div>
                <div className="pane pad-m">
                  <h3>{ t("LS-THE_BIG_IDEA") }</h3>
                  <p>{ getParsedHtml(the_big_idea) }</p>
                  <h3>{ t("LS-THE_CHALLENGE") }</h3>
                  <p>{ the_challenge }</p>
                </div>
              </div>
              <div>
                <div className="pane pad-s">
                  <h3>{ t("LS-MAIN_GOALS") }</h3>
                  <List type="lines" items={main_goals} />
                </div>
                <div className="pane pad-s">
                  <h3>{ t("LS-MAIN_MESSAGES") }</h3>
                  <List type="lines" items={main_messages} />
                </div>
              </div>
            </div>
          </Row>
        },

        {
          title: t("LEARNING_UNITS"),
          body: <Row>
            <section id="learning-units" className="wrapper-m flow">
              <h3 className="no-screen">{ t("LS-SHORT_ABSTRACT") }</h3>
              <p>{ short_abstract }</p>

              <div className="title-and-buttons flow-3x">
                <h2 className="page-break-before">{ t("LEARNING_UNITS") } ({ learning_units.length })</h2>

                <div className="no-print">
                  <button className="icon-button" onClick={ () => { handleOpenLusUpdate(false) } }>
                    { openLus.includes(true) ? <Compact /> : <CompactActive /> }
                    { t("LS-COMPACT_VIEW") }
                  </button>
                  <button className="icon-button" onClick={ () => { handleOpenLusUpdate(true) } }>
                    { openLus.includes(false) ? <Full /> : <FullActive /> }
                    { t("LS-FULL_VIEW") }
                  </button>
                </div>
              </div>

              <div id="ls-learning-units">
                { learning_units.map((lu, i) => {
                  return <LearningUnitItem key={i} number={i} lu={lu} open={openLus[i]} handleOpenLuUpdate={handleOpenLuUpdate} />
                }) }
              </div>
            </section>
          </Row>
        },

        {
          title: t("LS-TEACHER_FEEDBACK"),
          body: <Row>
            <section id="teacher-feedback" className="pane pad-m">
              <h2>{ t("LS-TEACHER_FEEDBACK") }</h2>

              {/* { quote && <div className="quote">
                <blockquote>{ quote.quote }</blockquote>
                <p className="quoted">{ quote.quote_source }</p>
              </div> } */}

              <div className="flex-columns">
                <div>
                  <h3>{ t("LS-AHA_MOMENTS") }</h3>
                  <List items={aha_moments} type="bullets" />
                </div>
                <div>
                  <h3>{ t("LS-UH_OH_MOMENTS") }</h3>
                  <List items={uh_oh_moments} type="bullets" />
                </div>
                <div>
                  <h3>{ t("LS-GENERALS_TIPS") }</h3>
                  <List items={general_tips} type="bullets" />
                </div>
              </div>

            </section>
          </Row>
        }
      ]} />

    </Layout>
  )
}


export const query = graphql`
  query($language: String!, $gatsbyNodeId: String!, $drupalNodeId: String!) {
    locales: allLocale(filter: {language: {in: [$language, "pt", "en"]}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allNodeLearningScenario( filter: {drupal_id: {eq: $drupalNodeId}} ) {
      nodes {
        path {
          alias
          langcode
        }
      }
    }
    nodeLearningScenario(id: {eq: $gatsbyNodeId}) {
      id
      drupal_id
      title
      subtitle
      image {
        alt
        width
        height
      }
      maximum_age
      minimum_age
      duration
      short_abstract
      the_challenge
      the_big_idea
      main_goals
      main_messages
      uh_oh_moments
      aha_moments
      general_tips
      relationships {
        authors {
          persons
          relationships {
            organisation {
              title
              country
            }
          }
        }
        quote {
          quote_source
          quote
        }
        image {
          styles
          uri {
            url
          }
        }
        core_subject {
          name
        }
        learning_units {
          relationships {
            learning_unit_reference {
              id
              title
              number
              short_abstract
              teaser
              teaching_time_minutes
              duration_repeat
              path {
                alias
              }
              relationships {
                phase {
                  name
                  drupal_id
                }
                format {
                  name
                }
                location {
                  name
                }
                partners {
                  name
                }
                engagement {
                  name
                }
                steam_subjects {
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`

export default LearningScenario
